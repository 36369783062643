var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "환경, 안전보건 KPI 실적 목록",
            merge: _vm.grid.merge,
            columns: _vm.grid.columns,
            data: _vm.target.targetList,
            gridHeight: _vm.gridHeight,
            editable: _vm.editable && !_vm.disabled,
            usePaging: false,
            isExcelDown: true,
            hideBottom: true,
            filtering: false,
            isFullScreen: true,
            gridHeightAuto: true,
            columnSetting: false,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  props.row["ehsFlag"] === "N" && !_vm.disabled
                    ? [
                        _c("c-number-column", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            col: col,
                            props: props,
                          },
                          on: {
                            datachange: function ($event) {
                              return _vm.datachange(props, col)
                            },
                          },
                          model: {
                            value: props.row["performanceCount"],
                            callback: function ($$v) {
                              _vm.$set(props.row, "performanceCount", $$v)
                            },
                            expression: "props.row['performanceCount']",
                          },
                        }),
                      ]
                    : [_vm._v(" " + _vm._s(props.row.performanceCount) + " ")],
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "suffixTitle" },
            [
              _c(
                "font",
                {
                  staticClass: "text-negative",
                  staticStyle: { "font-size": "0.9em", "font-weight": "500" },
                },
                [
                  _vm._v(
                    " ※ 실적(건)은 작성월 기준 전월 데이터가 자동 조회됩니다. (직접 입력은 제외) "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "div",
        { staticStyle: { "padding-top": "25px!important" } },
        [
          _c("c-upload", {
            attrs: {
              attachInfo: _vm.attachInfo,
              editable: _vm.editable && !_vm.disabled,
              label: "관련 파일",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }