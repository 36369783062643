<template>
  <div>
    <c-table
      ref="table"
      title="환경, 안전보건 KPI 실적 목록"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="target.targetList"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :usePaging="false"
      :isExcelDown="true"
      :hideBottom="true"
      :filtering="false"
      :isFullScreen="true"
      :gridHeightAuto="true"
      :columnSetting="false"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row['ehsFlag'] === 'N' && !disabled">
          <!-- input number -->
          <c-number-column
            :editable="editable"
            :disabled="disabled"
            :col="col"
            :props="props"
            v-model="props.row['performanceCount']"
            @datachange="datachange(props, col)"
          />
        </template>
        <template v-else>
          {{props.row.performanceCount}}
        </template>
      </template>
      <template slot="suffixTitle">
        <font class="text-negative" style="font-size:0.9em;font-weight:500;">
          ※ 실적(건)은 작성월 기준 전월 데이터가 자동 조회됩니다. (직접 입력은 제외)
        </font>
      </template>
    </c-table>
    <div style="padding-top:25px!important">
      <c-upload 
        :attachInfo="attachInfo"
        :editable="editable && !disabled"
        label="관련 파일">
      </c-upload>
    </div>
  </div>
</template>
<script>
export default {
  name: 'env-saf-kpi-result',
  props: {
    target: {
      type: Object,
      default: () => ({
        sopEnvSafKpiId: '',
        plantCd: '',
        targetMonth: '',
        envSafStatusCd: '',
        targetTitle: '',
        writeDeptCd: '',
        regUserId: '',
        chgUserId: '',
        targetList: [],
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'ENV_SAF_KPI',
        taskKey: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        merge: [{ index: 0, colName: "typeName" }],
        columns: [
          {
            name: 'typeName',
            field: 'typeName',
            label: '구분',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'targetName',
            field: 'targetName',
            label: '점검내용',
            align: 'center',
            style: 'width: 350px',
            sortable: false,
          },
          {
            name: 'targetCount',
            field: 'targetCount',
            label: '목표(건)',
            style: 'width: 80px',
            align: 'right',
            sortable: false,
          },
          {
            name: 'performanceCount',
            field: 'performanceCount',
            label: '실적(건)',
            style: 'width: 80px',
            align: 'right',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'achievementRate',
            field: 'achievementRate',
            label: '달성율(%)',
            style: 'width: 80px',
            align: 'right',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = (this.contentHeight - 700);
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    },
    disabled() {
      return this.target.envSafStatusCd === 'ESK0000005'
        // 결재중인 경우 비활성화
        || this.target.approvalStatusCd === 'ASC0000001'
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    datachange(props, col) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
      if (props.row['zeroFlag'] === 'Y') {
        if (props.row['performanceCount'] > 0) {
          this.$set(props.row, 'achievementRate', 0)
        } else {
          this.$set(props.row, 'achievementRate', 100)
        }
      } else {
        let rate = Math.round(props.row['performanceCount']/props.row['targetCount']*100);

        if (rate > 100) {
          this.$set(props.row, 'achievementRate', 100);        
        } else {
          this.$set(props.row, 'achievementRate', rate);        
        }
      }
    },
  }
};
</script>
